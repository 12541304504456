<template>
  <div class="info">
    <div class="info_staff">
      <span>姓名</span>
      <p>{{ staffAllInfo.name }}</p>
      <span>电话号码</span>
      <p>{{ staffAllInfo.tel }}</p>
<!--      <span>身份证号码</span>-->
<!--      <p>{{staffAllInfo.id_card}}</p>-->
      <span>场地</span>
      <p>{{staffAllInfo.place}}</p>
        <span>场地负责人</span>
        <p style="font-size: 20px">{{[contact.name,contact.tel].join('，')}}</p>
    </div>
<!--    <div class="info_gift" v-show="!staffAllInfo.isGift">-->
<!--      <p class="info_gift_title">物流信息</p>-->
<!--      <ul class="info_gift_detail">-->
<!--        <li><span>姓名</span><p>{{staffAllInfo.gift.name}}</p></li>-->
<!--        <li><span>电话</span><p>{{ staffAllInfo.gift.tel }}</p></li>-->
<!--        <li><span>地址</span><p>{{ staffAllInfo.gift.city[0]}}-->
<!--          {{ staffAllInfo.gift.city[1]=='市辖区'?'':staffAllInfo.gift.city[1]}}-->
<!--          {{ staffAllInfo.gift.city[2]}}-->
<!--          {{ staffAllInfo.gift.addr }}</p></li>-->
<!--      </ul>-->
<!--    </div>-->
    <div class="info_warn">
      <p>如有问题请联系：<a href="mailto:leqiliao@imprforest.com">leqiliao@imprforest.com</a></p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import staff from "@/pages/register/staff.vue";

export default {
  name: "info",
  data(){
    return{
      staffAllInfo:{
        name:'',
        id:'',
        email:'',
        tel:'',
        id_card:'',
        place:'',
        gift:{addr:'',name:'',tel:'',city:[]},
        entourages:[{name:'', idCard:''}],
      },
      contact: {name: '', tel: '', check_point: ''}
    }
  },
    computed:{
      ...mapState({
          placeInfo: state => state.register.checkPoints
      }),
    },
  mounted(){
    this.pageTitle('报名信息')
    this.verifyInformation().then((rsp)=>{
      this.staffAllInfo = rsp.data.register
      // console.log('111',rsp.data.register);
        var result = this.placeInfo.find(item=>item.name == this.staffAllInfo.place);
        if(result){
            this.contact = result.contact;
        }

      this.staffAllInfo.gift = JSON.parse(this.staffAllInfo.gift_info)
      // console.log(this.staffAllInfo);
    }).catch(e=>{

    })

  },
  // computed:{
  //   ...mapState({
  //     staffAllInfo:state => state.register.staff
  //   })
  // },

  methods: {
    ...mapActions({
      pageTitle: 'register/pageTitle',
      verifyInformation:'register/verifyInformation',
      checkLogin: 'passport/loadProfile'
    }),
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
p
  margin 0
ul,li
  list-style-type none
  padding 0
  margin 0
.info
  background-color white
  padding 0 38px 61px
  .info_staff
    padding-top 20px
    padding-bottom 20px
    text-align center
    span
      infoSpanSet()
    p
      infoPSet()
      font-size 48px
      padding-bottom 27px
  .info_entourage
    padding-bottom 60px
    .info_entourage_title
      text-align center
      font-size 24px
      font-family "source han sans cn"
      color #33CC00
      line-height 48px
      padding 12px 0 30px
    .info_entourage_item
      width 670px
      //height 130px
      background #F5F5F5
      border-radius 10px
      display flex
      flex-direction column
      justify-content space-between
      padding 25px 0 25px 37px
      margin-bottom 10px
      box-sizing border-box
      li
        display flex
        flex-direction row
        span
          width 3em
          infoSpanSet()
          text-align: justify;
          text-align-last: justify;
          padding-right 28px
          line-height 34px
        p
          infoPSet()
          font-size 36px
          line-height 34px
  .info_gift
    border-top  2px solid #CCCCCC
    padding-bottom 60px
    .info_gift_title
      titleSet()
      padding 55px 0
    .info_gift_detail
      height 180px
      display flex
      flex-direction column
      justify-content space-around
      li
        display flex
        flex-direction row
        padding-left 37px
        span
          width 3em
          infoSpanSet()
          text-align: justify;
          text-align-last: justify;
          padding-right 28px
          line-height 48px
        p
          width 520px
          infoPSet()
          font-size 36px
          line-height 48px
          word-wrap break-word

  .info_warn
    border-top  2px solid #CCCCCC
    padding-top 20px
    p
      infoSpanSet()
      font-size 24px
      text-align center
</style>
